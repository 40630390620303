import React, { useState, useEffect } from "react"
import Layout from "@layouts/layout"
import { Intro, Block, Container, FilteredList, Loading } from "@components"
import axios from "axios"
import { graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { getPricing } from "@context/productContext"
import { gtmPush } from "@helpers/gtmHelpers"
import ModuleArea from "../helpers/moduleArea"
import queryString from "query-string"

const Clearance = ({ data, pageContext, location }) => {
  const { content, products, globalConfig, brands } = data

  const isClearance = pageContext.title === "Clearance" ? true : false

  const getBrand = brand => {
    return brands.nodes.find(({ name }) => name === brand)
  }

  const uniqueClearanceTags = [
    ...new Set(
      products.nodes
        .map(product => product.clearanceTag) // Extract the clearanceTag
        .filter(tag => tag && tag !== "") // Filter out null, undefined, and empty strings
    )
  ]
    .map(tag => ({ text: tag, value: tag })) // Map the tags into objects
    .filter(tag => tag.text && tag.value) // Filter out any objects with null/undefined text or value
    .sort((a, b) => a.value.localeCompare(b.value)) // Sort alphabetically by value

  const [availability, setAvailability] = useState({
    loading: true,
    isEmpty: products.nodes.length === 1,
    productStatuses: new Map(),
    errors: null
  })

  const params = queryString.parse(location.search)
  const initialLocationFilter = params.location || ""

  useEffect(() => {
    ;(async () => {
      const specialsSkus = products.nodes.map(({ sku }) => sku)
      const uniqueSpecialsSkus = [...new Set(specialsSkus)]

      let response = []

      try {
        response = await axios.post(
          "/.netlify/functions/stockAvailability",
          { skus: uniqueSpecialsSkus },
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
      } catch (err) {
        console.log("errors", err)
      }

      const newProductStatuses = new Map()

      if (response.data && Object.keys(response.data).length > 0) {
        Object.values(response.data).forEach(item => {
          newProductStatuses.set(item.sku, item.status)
        })
      }

      setAvailability({
        loading: false,
        isEmpty: products.nodes.length < 2, // needs to be less than 2 because there is 1 hidden clearance item
        productStatuses: newProductStatuses,
        errors: errors
      })
    })()
  }, [products.nodes])

  const { loading, productStatuses, isEmpty, errors } = availability

  const clearanceItems = products.nodes
    .map(product => {
      if (product?.warehouseData?.WarehouseState) {
        product.location = product.warehouseData.WarehouseState
        product.state = product.warehouseData.WarehouseState
      }
      if (
        !product?.warehouseData?.WarehouseState &&
        product?.warehouseData?.WarehousePostcode
      ) {
        product.location = product.warehouseData.WarehousePostcode
      }
      if (
        product?.warehouseData?.WarehouseState &&
        product?.warehouseData?.WarehousePostcode
      ) {
        product.location = `${product.warehouseData.WarehouseState}, ${product.warehouseData.WarehousePostcode}`
      }

      const details = []

      if (product?.datoProduct?.specs.length > 0) {
        product.datoProduct.specs.map(spec => {
          details.push({
            name: spec.label,
            value:
              spec.value ||
              product[
                spec.label
                  .trim()
                  .toLowerCase()
                  .replace(/ /gi, "-")
              ] ||
              product.specifics.find(specific => specific.name === spec.label)
                ?.value ||
              null
          })
        })
      }

      if (
        details.findIndex(detail => detail.name.toLowerCase() === "serial") ===
        -1
      ) {
        details.push({
          name: "Serial",
          value: product.serial
        })
      }
      if (product?.clearanceNote) {
        details.push({
          name: "Note",
          value: product.clearanceNote
        })
      }
      // if (product?.condition) {
      //   details.push({
      //     name: "Condition",
      //     value: product.condition
      //   })
      // }
      if (
        product.location &&
        details.findIndex(
          detail => detail.name.toLowerCase() === "location"
        ) === -1
      ) {
        details.unshift({
          name: "Location",
          value: product.location
        })
      }

      const transformedProduct = {
        title: product?.name,
        sku: product?.serial,
        category: product?.categories[0],
        condition: product?.condition,
        clearanceNote: product?.clearanceNote,
        clearanceUrl: product?.clearanceUrl,
        clearanceTag: product?.clearanceTag,
        clearancePscOffer: product?.clearancePscOffer,
        // description: product?.description,
        hover: true,
        elevated: true,
        padImage: true,
        imageDisclaimer: true,
        extended: true,
        special: true,
        hidden: product?.serial === "NO-CLEARANCE-ITEM" ? true : false,
        heading: product?.datoProduct?.name || product.name,
        brand: product?.datoProduct?.brand || product.brand,
        pricing: getPricing(product),
        id: product.id,
        state: product.state,
        dimensions:
          product?.datoProduct?.featuredSpecs.length > 0 &&
          product.datoProduct.featuredSpecs.map(featuredSpec => {
            return {
              icon: featuredSpec.icon,
              name: featuredSpec.label,
              label:
                featuredSpec.value ||
                product[
                  featuredSpec.label
                    .trim()
                    .toLowerCase()
                    .replace(/ /gi, "-")
                ] ||
                product.specifics.find(
                  specific => specific.name === featuredSpec.label
                )?.value ||
                null
            }
          }),
        details,
        image:
          product?.datoProduct?.image ||
          data?.globalConfig?.specialsImagePlaceholder,
        links: []
      }

      transformedProduct.links.push(
        {
          title: "Get a Quote",
          to: `/contact-us/specials-enquiry`,
          state: {
            heading: transformedProduct.heading,
            image: transformedProduct.image,
            brand: transformedProduct.brand,
            details: transformedProduct.details,
            price: transformedProduct?.pricing?.price,
            sku: transformedProduct.serial,
            isClearance: isClearance,
            clearanceUrl: transformedProduct.clearanceUrl,
            clearanceTag: transformedProduct.clearanceTag
          }
        },
        {
          title: "Get a Quote",
          to: `/contact-us/specials-enquiry`,
          state: {
            heading: transformedProduct.heading,
            image: transformedProduct.image,
            brand: transformedProduct.brand,
            details: transformedProduct.details,
            price: transformedProduct?.pricing?.price,
            sku: transformedProduct.serial,
            isClearance: isClearance,
            clearanceUrl: transformedProduct.clearanceUrl,
            clearanceTag: transformedProduct.clearanceTag
          }
        }
      )

      transformedProduct.isAvailable = productStatuses.get(
        transformedProduct.serial
      )

      return transformedProduct
    })
    .sort((a, b) => a?.pricing?.price - b?.pricing?.price)

  const meta = { tags: [] }

  for (const [key, value] of Object.entries(globalConfig.metaTags)) {
    if (!value) continue

    if (key === "title") {
      meta.tags.push(
        {
          tagName: key,
          content: value
        },
        {
          tagName: "meta",
          attributes: { property: `og:${key}`, content: value }
        },
        {
          tagName: "meta",
          attributes: { name: `twitter:${key}`, content: value }
        }
      )
    }

    if (key === "description") {
      meta.tags.push(
        {
          tagName: "meta",
          attributes: { name: `${key}`, content: value }
        },
        {
          tagName: "meta",
          attributes: { property: `og:${key}`, content: value }
        },
        {
          tagName: "meta",
          attributes: { name: `twitter:${key}`, content: value }
        }
      )
    }

    if (key === "twitterCard") {
      meta.tags.push({
        tagName: "meta",
        attributes: { name: `twitter:card`, content: value }
      })
    }

    if (key === "image") {
      meta.tags.push(
        {
          tagName: "meta",
          attributes: { property: `og:${key}`, content: value.url }
        },
        {
          tagName: "meta",
          attributes: { name: `twitter:${key}`, content: value.url }
        }
      )
    }
  }

  useEffect(() => {
    const ecommerceItem = []
    if (clearanceItems.length > 0) {
      clearanceItems.forEach(item => {
        ecommerceItem.push({
          item_id: item.sku,
          item_name: item.title,
          affiliation: "Spa World",
          item_brand: item.brand?.name || item.brand,
          item_category: item.category,
          item_variant: item.heading,
          item_list_name: pageContext.title,
          item_list_id: pageContext.title.toLowerCase().replaceAll(" ", "-"),
          price: item.pricing?.now,
          currency:
            pageContext.language === process.env.GATSBY_LANGUAGE
              ? "AUD"
              : "NZD",
          quantity: "1"
        })
      })

      gtmPush({ ecommerce: null })
      gtmPush({
        event: "view_item_list",
        ecommerce: {
          item_list_name: pageContext.title,
          item_list_id: pageContext.title.toLowerCase().replaceAll(" ", "-"),
          items: ecommerceItem
        }
      })
    }
  }, [products])

  const ecommerceOnClick = item => {
    const ecommerceItem = [
      {
        item_id: item.sku,
        item_name: item.title,
        affiliation: "Spa World",
        item_brand: item.brand?.name || item.brand,
        item_category: item.category,
        item_variant: item.heading,
        item_list_name: pageContext.title,
        item_list_id: pageContext.title.toLowerCase().replaceAll(" ", "-"),
        price: item.pricing?.now,
        currency:
          pageContext.language === process.env.GATSBY_LANGUAGE ? "AUD" : "NZD",
        quantity: "1"
      }
    ]

    gtmPush({ ecommerce: null })
    gtmPush({
      event: "select_item",
      ecommerce: {
        item_list_name: pageContext.title,
        item_list_id: pageContext.title.toLowerCase().replaceAll(" ", "-"),
        items: ecommerceItem
      }
    })
  }

  const filters = [
    content.regions.length > 0 && {
      name: "state",
      label: "location",
      placeholder: {
        text: "Select your location"
      },
      options: [...content.regions]
    },
    uniqueClearanceTags &&
      uniqueClearanceTags.length > 0 && {
        name: "clearanceTag",
        label: "category",
        placeholder: {
          text: "Select a category"
        },
        options: uniqueClearanceTags
      }
  ].filter(Boolean) // This removes any false, null, or undefined values

  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={meta} />
      <Block gutters>
        <Intro
          breadcrumbs={pageContext.breadcrumbs}
          title={globalConfig.heading}
          lead={globalConfig.lead}
          showHeroImage={false}
          banner={globalConfig.banner ? globalConfig.banner : null}
        />
      </Block>
      <ModuleArea modules={globalConfig.modularContent} />
      <div className={"clearance-layout"}>
        <Container>
          {loading && !isEmpty && (
            <Block padding="double">
              <Loading />
            </Block>
          )}

          {isEmpty && (
            <Block padding="double">
              <center>Sorry, there are currently no products available.</center>
            </Block>
          )}

          {!loading && !isEmpty && (
            <FilteredList
              items={clearanceItems}
              filters={filters}
              type="product"
              defaultQueries={new Map([["state", initialLocationFilter]])}
              location={location}
              onCardClick={ecommerceOnClick}
              itemsVisible={50}
            />
          )}
        </Container>
      </div>
    </Layout>
  )
}

export default Clearance

export const query = graphql`
  query clearanceProducts($language: String!) {
    products: allNetoSpecial(filter: { categories: { eq: "Clearance" } }) {
      nodes {
        ...ClearanceProductCard
        datoProduct: datoClearanceProduct {
          name
          id
          slug
          country
          serial: sku
          featuredSpecs {
            value
            model {
              apiKey
            }
            __typename
            label
            id
            icon {
              alt
              url
              gatsbyImageData(
                layout: FULL_WIDTH
                imgixParams: {
                  h: "26"
                  fit: "crop"
                  w: "26"
                  q: 60
                  auto: "format"
                }
              )
            }
          }
          specs {
            value
            model {
              apiKey
            }
            __typename
            label
            id
          }
          image(locale: $language) {
            gatsbyImageData(
              layout: FULL_WIDTH
              imgixParams: {
                h: "330"
                fit: "fill"
                fill: "solid"
                w: "500"
                q: 60
                auto: "format"
              }
            )
            alt
          }
          brand(locale: $language) {
            name
            slug
            id
            logo {
              alt
              url
            }
          }
        }
      }
    }

    content: contentYaml {
      regions {
        text
        value
      }
    }

    globalConfig: datoCmsGlobal {
      metaTags: clearancePageMetaTags {
        title
        description
        twitterCard
        image {
          url
        }
      }
      heading: clearanceHeading
      lead: clearanceLead
      modularContent: clearance {
        ... on DatoCmsContentWithImage {
          ...ContentWithImage
        }
        ... on DatoCmsContent {
          ...Content
        }
      }
      banner: clearanceBanner {
        id
        link {
          ...Link
        }
        image {
          url
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "295"
              w: "1170"
              fit: "crop"
              q: 60
              auto: "format"
            }
          )
          alt
        }
        mobileImage {
          url
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: {
              h: "175"
              w: "420"
              fit: "cover"
              q: 60
              auto: "format"
            }
          )
          alt
        }
      }
      specialsImagePlaceholder {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "330"
            fit: "fill"
            fill: "solid"
            w: "500"
            auto: "format"
          }
        )
        alt
      }
    }

    brands: allDatoCmsBrand {
      nodes {
        ...BrandTag
      }
    }
  }
`
